<template>
<div>
  <div v-if="page.image">
     <b-carousel fade>
      <b-carousel-slide v-for="(image, index) in images" 
        :key="index"
        :img-src="image">
      </b-carousel-slide>
     </b-carousel>
  </div>
  <b-container class="shlajsna py-5">
    <b-row>
      <b-col cols="12">
        <h1>{{page.title}}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="8" class="my-4">
        <div class="page" v-html="page.content">
        </div>
      </b-col>
      <b-col sm="4">
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';
export default {
  name: "SinglePage",
  components: {BCarousel, BCarouselSlide},
  data: function() {
    return {
      url : this.$route.params.page.toLowerCase(),
      page: {},
      images: []
    }
  },
  created: async function() {
    await this.$http.get("/api/page/get/" + this.lang.shortName + "/" + this.url).then(r => {
      this.page = r.data;
      this.images = JSON.parse(this.page.image);
      if (this.images !== null) {
        this.shuffle(this.images);
      }
    }).catch(error => {
      //console.log(error);
      if (error.response.status === 404) {
        this.page.title = "404"
        this.page.content = this.$t('page.not-found');
      }
    })
  },
  methods: {
    shuffle: function(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }
  },
  metaInfo: function() {
    return {
      title: this.page.title,
      description: this.page.description
    }
  },
  computed: {
    lang: function() {
      return this.$root.lang
    }
  },

  watch: {
     async lang(newLang, oldLang) {
      // console.log("Promena jezika SinglePage");
      // console.log("Stara stranica: " + oldLang.shortName);
      // console.log(this.page);
      await this.$http.get("/api/page/transpages/" + this.page.id)
        .then(r => {
          console.log("Preuzete odgovarajuće stranice.")
          // Pronađi odgovarajuću stranicu za promenjeni jezik
          for (let i = 0; i < r.data.length; i++) {
            const myPage = r.data[i];
            console.log("Strana: " + r.data[i].title);

            // console.log(myPage.language.shortName);
            if (newLang.shortName === myPage.language.shortName) {
              // console.log("Podudaranje!");
              this.page = myPage;
              this.images = JSON.parse(this.page.image);
              // console.log("Nova stranica: " + newLang.shortName);
              // console.log(this.page);
              break;
            }
          }
      }).catch(e => {
        console.log("Greška prilikom preuzimanaja odovarajućih stranica!");
        console.error(e);
        this.handleError(e);
      })

      // Ovo mora da se ostavi, jer će se Laki verovatno predomisliti.
      /*
      if (
          (oldLang.shortName === "sr-cir" && newLang.shortName === "sr-lat")  || 
          (oldLang.shortName === "sr-lat" && newLang.shortName === "sr-cir")
          ) {
        await this.$http.get("/api/page/get/" + newLang.shortName + "/" + this.url).then(r => {
          this.page = r.data;
          this.images = JSON.parse(this.page.image);
          if (this.images !== null) {
            this.shuffle(this.images);
          }
        }).catch(error => {
          if (error.response.status === 404) {
            this.page.title = "404"
            this.page.content = this.$t('page.not-found');
          }
        })
      } else {
        window.location = "/" + newLang.shortName + "/";
      }
      */
    }
  }
}
</script>
<style></style>
